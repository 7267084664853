import React, { useEffect, useState } from "react";
import "./Settings.scss";
import Card from "../../components/Card/Card";
import { TfiWrite } from "react-icons/tfi";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { RiContactsBook3Line } from "react-icons/ri";
import { GrSystem } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";
import { BASE_URL } from "../../utils/config";
import Loader from "../../components/Loader/Loader";
import { TbSettingsCog } from "react-icons/tb";
import { IoMdNotificationsOutline } from "react-icons/io";

const iconMapping = {
  GrSystem,
  RiContactsBook3Line,
  PiDownloadSimpleBold,
  TfiWrite,
  TbSettingsCog,
  IoMdNotificationsOutline,
};

const Settings = () => {
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchSetting = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/settings/navbar`);
        if (isMounted) {
          updateAuthToken();
          setSettings(response.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchSetting();
    return () => {
      isMounted = false;
    };
  }, []);
  if (!settings) return <Loader />;
  if (!settings || !settings.navbar) return <Loader />;
  return (
    <div className="settingPageContainer">
      <div className="settingPageContents">
        <div className="headerTitle">Settings</div>
        <div className="settingsCard">
          {settings.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`/settings${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Settings;
