import React from "react";
import "./Homepage.scss";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "../Dashboard/Dashboard";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import UserList from "../User/UserList/UserList";
import CreateUser from "../User/CreateUser/CreateUser";
import UserProfile from "../../components/UserProfile/UserProfile";
import Settings from "../Settings/Settings";
import NoticeList from "../Notice/NoticeList/NoticeList";
import CreateNotice from "../Notice/CreateNotice/CreateNotice";
import ViewNotice from "../Notice/ViewNotice/ViewNotice";
import CourseList from "../Course/CourseList/CourseList";
import CreateCourse from "../Course/CreateCourse/CreateCourse";
import TestimonialList from "../Testimonial/TestimonialList/TestimonialList";
import CreateTestimonial from "../Testimonial/CreateTestimonial/CreateTestimonial";
import FacultyList from "../Faculty/FacultyList/FacultyList";
import CreateFaculty from "../Faculty/CreateFaculty/CreateFaculty";
import EditFaculty from "../Faculty/EditFaculty/EditFaculty";
import ViewFaculty from "../Faculty/ViewFaculty/ViewFaculty";
import EditUser from "../User/EditUser/EditUser";
import UserDetail from "../User/UserDetail/UserDetail";
import ResourceList from "../Resource/ResourceList/ResourceList";
import CreateResource from "../Resource/CreateResource/CreateResource";
import EditTestimonial from "../Testimonial/EditTestimonial/EditTestimonial";
import ViewTestimonial from "../Testimonial/ViewTestimonial/ViewTestimonial";
import ChangePassword from "../ChangePassword/ChangePassword";
import NewsList from "../News/NewsList/NewsList";
import CreateNews from "../News/CreateNews/CreateNews";
import EditNews from "../News/EditNews/EditNews";
import SiteContent from "../Site/SiteContent/SiteContent";
import About from "../Site/About/About";
import Mission from "../Site/Mission/Mission";
import Vision from "../Site/Vision/Vision";
import PrincipalMessage from "../Site/PrincipalMessage/PrincipalMessage";
import DirectorMessage from "../Site/DirectorMessage/DirectorMessage";
import GalleryList from "../Gallery/GalleryList/GalleryList";
import CreateGallery from "../Gallery/CreateGallery/CreateGallery";
import ViewGallery from "../Gallery/ViewGallery/ViewGallery";
import Course from "../Course/Course";
import EventList from "../Event/EventList/EventList";
import CreateEvent from "../Event/CreateEvent/CreateEvent";
import ViewEvent from "../Event/ViewEvent/ViewEvent";
import CreateStudent from "../Student/CreateStudent/CreateStudent";
import CreateSemester from "../Semester/CreateSemester/CreateSemester";
import CreateSubject from "../Subject/CreateSubject/CreateSubject";
import Program from "../Program/Program";
import SemesterList from "../Semester/SemesterList/SemesterList";
import CreateRoutine from "../Routine/CreateRoutine/CreateRoutine";
import RoutineList from "../Routine/RoutineList/RoutineList";
import EditCourse from "../Course/EditCourse/EditCourse";
import EditRoutine from "../Routine/EditRoutine/EditRoutine";
import Setup from "../Setup/Setup";
import StudentList from "../Student/StudentList/StudentList";
import ViewStudent from "../Student/ViewStudent/ViewStudent";
import CreateExam from "../Exam/CreateExam/CreateExam";
import ExamList from "../Exam/ExamList/ExamList";
import CreateResult from "../Result/CreateResult/CreateResult";
import EditExam from "../Exam/EditExam/EditExam";
import ResultList from "../Result/ResultList/ResultList";
import EditResult from "../Result/EditResult/EditResult";
import ViewCourse from "../Course/ViewCourse/ViewCourse";
import SubjectList from "../Subject/SubjectList/SubjectList";
import EditSubject from "../Subject/EditSubject/EditSubject";
import SystemConfig from "../SystemConfig/SystemConfig";
import EventCategoryList from "../EventCategory/EventCategoryList/EventCategoryList";
import CreateEventCategory from "../EventCategory/CreateEventCategory/CreateEventCategory";
import FacultyCategoryList from "../FacultyCategory/FacultyCategoryList/FacultyCategoryList";
import CreateFacultyCategory from "../FacultyCategory/CreateFacultyCategory/CreateFacultyCategory";
import CreateNoticeCategory from "../NoticeCategory/CreateNoticeCategory/CreateNoticeCategory";
import NoticeCategoryList from "../NoticeCategory/NoticeCategoryList/NoticeCategoryList";
import ExamConfigList from "../ExamConfig/ExamConfigList/ExamConfigList";
import CreateExamConfig from "../ExamConfig/CreateExamConfig/CreateExamConfig";
import EditExamConfig from "../ExamConfig/EditExamConfig/EditExamConfig";
import CreateExamRelation from "../ExamRelation/CreateExamRelation/CreateExamRelation";
import ExamRelationList from "../ExamRelation/ExamRelationList/ExamRelationList";
import EditExamRelation from "../ExamRelation/EditExamRelation/EditExamRelation";
import Exam from "../Exam/Exam";
import NotifierList from "../Notifier/NotifierList/NotifierList";
import EditStudent from "../Student/EditStudent/EditStudent";
import EditEvent from "../Event/EditEvent/EditEvent";
import ImportStudent from "../Student/ImportStudent/ImportStudent";

const Homepage = () => {
  return (
    <div className="homepageContainer">
      <div className="homepageContents">
        <div className="homepageTop">
          <div className="navbarArea">
            <Navbar />
          </div>
        </div>
        <div className="middle">
          <div className="homepageContents_left">
            <div className="sidebarArea">
              <Sidebar />
            </div>
          </div>
          <div className="homepageContents_right">
            <Routes>
              <Route path="profile" element={<UserProfile />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="adminUser" element={<UserList />} />
              <Route path="adminUser/create" element={<CreateUser />} />
              <Route path="adminUser/edit/:uuid" element={<EditUser />} />
              <Route path="/adminUser/view/:uuid" element={<UserDetail />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="settings" element={<Settings />} />
              <Route
                path="settings/systemConfiguration"
                element={<SystemConfig />}
              />
              <Route path="settings/siteManagement" element={<SiteContent />} />
              <Route path="setup/notice" element={<NoticeList />} />
              <Route path="setup/notice/create" element={<CreateNotice />} />
              <Route path="setup/notice/view/:uuid" element={<ViewNotice />} />
              <Route path="setup/course" element={<Course />} />
              <Route path="setup/course/courses" element={<CourseList />} />
              <Route
                path="setup/course/courses/create"
                element={<CreateCourse />}
              />
              <Route
                path="setup/course/courses/view/:uuid"
                element={<ViewCourse />}
              />
              <Route
                path="setup/course/courses/edit/:uuid"
                element={<EditCourse />}
              />
              <Route path="setup/faculty" element={<FacultyList />} />
              <Route path="setup/faculty/create" element={<CreateFaculty />} />
              <Route
                path="setup/faculty/edit/:uuid"
                element={<EditFaculty />}
              />
              <Route
                path="setup/faculty/view/:uuid"
                element={<ViewFaculty />}
              />
              <Route path="student" element={<StudentList />} />
              <Route
                path="settings/testimonials"
                element={<TestimonialList />}
              />
              <Route
                path="settings/testimonials/create"
                element={<CreateTestimonial />}
              />
              <Route
                path="settings/testimonials/edit/:uuid"
                element={<EditTestimonial />}
              />
              <Route
                path="settings/testimonials/view/:uuid"
                element={<ViewTestimonial />}
              />
              <Route path="settings/resources" element={<ResourceList />} />
              <Route
                path="settings/resources/create"
                element={<CreateResource />}
              />
              <Route path="setup/news" element={<NewsList />} />
              <Route path="setup/news/create" element={<CreateNews />} />
              <Route path="setup/news/edit/:slug" element={<EditNews />} />
              <Route path="settings/siteManagement/about" element={<About />} />
              <Route
                path="settings/siteManagement/mission"
                element={<Mission />}
              />
              <Route
                path="settings/siteManagement/vision"
                element={<Vision />}
              />
              <Route
                path="settings/siteManagement/principalMessage"
                element={<PrincipalMessage />}
              />
              <Route
                path="settings/siteManagement/directorMessage"
                element={<DirectorMessage />}
              />
              <Route path="setup/gallery" element={<GalleryList />} />
              <Route path="setup/gallery/create" element={<CreateGallery />} />
              <Route
                path="setup/gallery/view/:slug"
                element={<ViewGallery />}
              />
              <Route path="setup/event" element={<EventList />} />
              <Route path="setup/event/create" element={<CreateEvent />} />
              <Route path="setup/event/view/:slug" element={<ViewEvent />} />
              <Route path="setup/event/edit/:slug" element={<EditEvent />} />
              <Route path="student/create" element={<CreateStudent />} />
              <Route path="student/import" element={<ImportStudent />} />
              <Route path="/student/view/:uuid" element={<ViewStudent />} />
              <Route path="/student/edit/:uuid" element={<EditStudent />} />
              <Route path="setup/routine" element={<RoutineList />} />
              <Route path="setup/routine/create" element={<CreateRoutine />} />
              <Route
                path="setup/routine/edit/:uuid"
                element={<EditRoutine />}
              />
              <Route path="setup/course/subject" element={<Program />} />
              <Route
                path="setup/course/subject/:uuid/create"
                element={<CreateSubject />}
              />
              <Route
                path="setup/course/subject/:uuid"
                element={<SubjectList />}
              />
              <Route
                path="setup/course/subject/:uuid/edit/:uuid"
                element={<EditSubject />}
              />
              <Route path="setup/course/semester" element={<Program />} />
              <Route
                path="setup/course/semester/:uuid"
                element={<SemesterList />}
              />
              <Route
                path="setup/course/semester/:uuid/create"
                element={<CreateSemester />}
              />

              <Route path="setup" element={<Setup />} />

              <Route path="setup/exam" element={<Exam />} />
              <Route path="setup/exam/exams" element={<ExamList />} />
              <Route path="setup/exam/exams/create" element={<CreateExam />} />
              <Route
                path="setup/exam/exams/edit/:uuid"
                element={<EditExam />}
              />
              <Route path="setup/result" element={<ResultList />} />
              <Route path="setup/result/create" element={<CreateResult />} />
              <Route path="setup/result/edit/:uuid" element={<EditResult />} />

              <Route
                path="setup/exam/examConfig"
                element={<ExamConfigList />}
              />
              <Route
                path="setup/exam/examConfig/create"
                element={<CreateExamConfig />}
              />
              <Route
                path="setup/exam/examConfig/edit/:uuid"
                element={<EditExamConfig />}
              />

              <Route
                path="setup/exam/examRelation"
                element={<ExamRelationList />}
              />
              <Route
                path="setup/exam/examRelation/edit/:uuid"
                element={<EditExamRelation />}
              />
              <Route
                path="setup/exam/examRelation/create"
                element={<CreateExamRelation />}
              />

              <Route
                path="settings/systemConfiguration/eventCategory"
                element={<EventCategoryList />}
              />
              <Route
                path="settings/systemConfiguration/eventCategory/create"
                element={<CreateEventCategory />}
              />

              <Route
                path="settings/systemConfiguration/facultyCategory"
                element={<FacultyCategoryList />}
              />
              <Route
                path="settings/systemConfiguration/facultyCategory/create"
                element={<CreateFacultyCategory />}
              />

              <Route
                path="settings/systemConfiguration/noticeCategory"
                element={<NoticeCategoryList />}
              />
              <Route
                path="settings/systemConfiguration/noticeCategory/create"
                element={<CreateNoticeCategory />}
              />

              <Route
                path="settings/notificationConfig"
                element={<NotifierList />}
              />
            </Routes>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Homepage;
