import React, { useEffect, useState } from "react";
import "./Program.scss";
import { Link, useNavigate } from "react-router-dom";
import { adminRequest } from "../../utils/requestMethods";
import { BASE_URL } from "../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiBookshelf } from "react-icons/gi";
import Card from "../../components/Card/Card";
import Loader from "../../components/Loader/Loader";
import { FaArrowLeftLong } from "react-icons/fa6";

const Program = () => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchProgram = async () => {
      setIsLoading(true);
      try {
        const response = await adminRequest.get(`${BASE_URL}/course/getName`);
        setPrograms(response.data.data);
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProgram();
  }, []);

  return (
    <div className="programContainer">
      <div className="programContents">
        <div className="headerTitle">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          Select Course
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="programCard">
            {programs &&
              programs.map((item, index) => {
                return (
                  <Link
                    to={`${item.uuid}`}
                    style={{ textDecoration: "none" }}
                    key={index}
                  >
                    <Card
                      icon={<GiBookshelf /> ? <GiBookshelf /> : null}
                      title={item.short_name}
                    />
                  </Link>
                );
              })}
          </div>
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Program;
