import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";

const EditCourse = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[5];
  const initialFormData = {
    name: "",
    desc: "",
    semester_count: "",
    subject_count: "",
    duration: "",
    seat: "",
    credit_hours: "",
    stream: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/course/view`, {
          uuid: `${activeURL}`,
        });
        if (isMounted) {
          setData(response.data);
          setFormData({
            name: response.data.data.name,
            desc: response.data.data.desc,
            semester_count: response.data.data.semester_count,
            subject_count: response.data.data.subject_count,
            duration: response.data.data.duration,
            seat: response.data.data.seat,
            credit_hours: response.data.data.credit_hours,
            stream: response.data.data.stream,
          });
        }
      } catch (error) {}
    };

    fetchCourse();
    return () => {
      isMounted = false;
    };
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/course/update`, {
          uuid: activeURL,
          name: formData.name,
          desc: formData.desc,
          semester_count: formData.semester_count,
          subject_count: formData.subject_count,
          duration: formData.duration,
          seat: formData.seat,
          credit_hours: formData.credit_hours,
          stream: formData.stream,
        }),
        {
          pending: "Updating Course",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "semester_count",
      label: "No. of Semester",
      type: "number",
      value: formData.semester_count,
      onChange: handleChange,
    },
    {
      name: "subject_count",
      label: "No. of Subject",
      type: "number",
      value: formData.subject_count,
      onChange: handleChange,
    },
    {
      name: "duration",
      label: "Duration",
      type: "text",
      value: formData.duration,
      onChange: handleChange,
    },
    {
      name: "seat",
      label: "Seat",
      type: "number",
      value: formData.seat,
      onChange: handleChange,
    },
    {
      name: "credit_hours",
      label: "Credit Hours",
      type: "number",
      value: formData.credit_hours,
      onChange: handleChange,
    },
    {
      name: "stream",
      label: "Stream",
      type: "text",
      value: formData.stream,
      onChange: handleChange,
    },
    {
      name: "desc",
      label: "Description",
      type: "textarea",
      value: formData.desc,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editCourseContainer">
      <CustomForm
        header="Edit Course"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditCourse;
