import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";

const CreateEvent = () => {
  const initialFormData = {
    name: "",
    eventDate: "",
    eventEndDate: null,
    eventTime: null,
    eventCategoryId: "",
    location: null,
    description: "",
    poster: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventCategory, setEventCategory] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, poster: e.target.files[0] });
  };

  useEffect(() => {
    const fetchEventCategory = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/event/category/listActive`
        );
        setEventCategory(response.data.data);
        updateAuthToken();
      } catch (error) {}
    };
    fetchEventCategory();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    formDataToSend.append("poster", formData.poster);

    formDataToSend.append(
      "event",
      JSON.stringify({
        name: formData.name,
        eventDate: formData.eventDate,
        eventEndDate: formData.eventEndDate,
        eventTime: formData.eventTime,
        event_category_id: formData.eventCategoryId,
        location: formData.location,
        description: formData.description,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/event/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Event Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "eventDate",
      label: "Event Date",
      type: "date",
      value: formData.eventDate,
      onChange: handleChange,
    },
    {
      name: "eventEndDate",
      label: "Event End Date",
      type: "date",
      value: formData.eventEndDate,
      onChange: handleChange,
    },
    {
      name: "eventTime",
      label: "Event Time",
      type: "time",
      value: formData.eventTime,
      onChange: handleChange,
    },
    {
      name: "eventCategoryId",
      label: "Event Category",
      type: "select",
      value: formData.eventCategoryId,
      onChange: handleChange,
      options: [
        { label: "Select Event Category", value: "" },
        ...eventCategory.map((ec) => ({
          label: ec.name,
          value: ec.id,
        })),
      ],
    },
    {
      name: "location",
      label: "Location",
      type: "text",
      value: formData.location,
      onChange: handleChange,
    },
    {
      name: "poster",
      label: "Poster",
      type: "file",
      onChange: handleImageChange,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: formData.description,
      onChange: handleChange,
    },
  ];
  return (
    <div className="createEventContainer">
      <CustomForm
        header="Create Event"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Event"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateEvent;
