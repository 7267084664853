import React, { useEffect, useRef, useState } from "react";
import "./StudentList.scss";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "../../../components/NotFound/NotFound";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoIosAddCircle, IoIosMore } from "react-icons/io";
import CustomForm from "../../../components/CustomForm/CustomForm";
import OptionsMenu from "../../../components/OptionsMenu/OptionsMenu";
import NoRecord from "../../../components/NoRecord/NoRecord";
import { CiSearch } from "react-icons/ci";
import { BsFiletypeCsv } from "react-icons/bs";
import _ from "lodash";

const StudentList = () => {
  const [rows, setRows] = useState([]);
  const headers = [
    "Full Name",
    "Email",
    "Phone Number",
    "Enrollment Date",
    "Status",
  ];
  const initialFormData = {
    enrolledProgram: "",
    enrolledSemester: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [program, setProgram] = useState([]);
  const [semester, setSemester] = useState([]);
  const navigate = useNavigate();
  const [visibleMenu, setVisibleMenu] = useState(null);
  const menuRefs = useRef([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [promotionSemester, setPromotionSemester] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const handlePromoteClick = () => {
    if (selectedStudents.length === 0) {
      return;
    }
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleDialogSubmit = async () => {
    if (!promotionSemester) {
      return;
    }

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/student/promote`, {
          toSemester: promotionSemester,
          studentUuid: selectedStudents,
        }),
        { pending: "Processing your reques" }
      );
      toast.success(response.data.message, {
        autoClose: 1000,
        onClose: () => window.location.reload(),
      });
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setProgram(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    if (formData.enrolledProgram) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.enrolledProgram,
            }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.log(error);
        }
      };
      fetchSemester();
    }
    fetchProgram();
  }, [formData.enrolledProgram]);

  const fetchStudentList = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/student/listAll`);
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.fullName,
          e.email,
          e.phoneNumber,
          e.enrollmentDate,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    }
  };
  useEffect(() => {
    fetchStudentList();
  }, []);

  const fetchData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/student/list`, {
        uuid: formData.enrolledSemester,
      });
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.fullName,
          e.email,
          e.phoneNumber,
          e.enrollmentDate,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    }
  };
  useEffect(() => {
    fetchData();
  }, [formData.enrolledSemester]);

  const fetchStudentByCourse = async () => {
    try {
      const response = await adminRequest.post(
        `${BASE_URL}/student/listByCourse`,
        {
          id: formData.enrolledProgram,
        }
      );
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.fullName,
          e.email,
          e.phoneNumber,
          e.enrollmentDate,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    }
  };
  useEffect(() => {
    fetchStudentByCourse();
  }, [formData.enrolledProgram]);

  const handleCreateButtonClick = () => {
    navigate("/student/create");
  };
  const handleImportButtonClick = () => {
    navigate("/student/import");
  };

  const handleMoreClick = (rowIndex) => {
    setVisibleMenu(visibleMenu === rowIndex ? null : rowIndex);
  };
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedStudents(rows.map((student) => student.uuid));
    } else {
      setSelectedStudents([]);
    }
  };

  const handleSelectStudent = (studentUuid, checked) => {
    if (checked) {
      setSelectedStudents((prev) => [...prev, studentUuid]);
    } else {
      setSelectedStudents((prev) =>
        prev.filter((uuid) => uuid !== studentUuid)
      );
      setSelectAll(false);
    }
  };

  const handleSearch = React.useCallback(
    _.debounce(async (query) => {
      if (!query) {
        fetchStudentList();
        return;
      }
      try {
        const response = await adminRequest.post(`${BASE_URL}/student/search`, {
          searchKeyword: query,
        });
        const fetchedRows = response.data.data.map((e) => ({
          displayData: [
            e.fullName,
            e.email,
            e.phoneNumber,
            e.enrollmentDate,
            e.status,
          ],
          uuid: e.uuid,
          status: e.status,
        }));
        setRows(fetchedRows);
      } catch (error) {
        console.error(error);
      }
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    handleSearch(value);
  };

  const filterFields = [
    {
      name: "enrolledProgram",
      label: "Enrolled Program",
      type: "select",
      value: formData.enrolledProgram,
      onChange: handleChange,
      options: [
        { label: "Select Program", value: "" },
        ...program.map((p) => ({
          label: p.short_name,
          value: p.id,
        })),
      ],
    },
    {
      name: "enrolledSemester",
      label: "Semester",
      type: "select",
      value: formData.enrolledSemester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((s) => ({
          label: s.semester,
          value: s.uuid,
        })),
      ],
    },
  ];
  const getMenuItems = (row) => [
    { link: `view/${row.uuid}`, text: "View" },
    { link: `edit/${row.uuid}`, text: "Edit" },
  ];

  return (
    <div className="studentListContainer">
      <div className="studentListContents">
        <div className="top">
          <span className="leftTitle">
            <FaArrowLeftLong onClick={handleBackClick} className="icon" />
            Student List
          </span>
          <span className="rightContents">
            <span className="searchField">
              <input
                type="text"
                name="search"
                placeholder="Search Students..."
                className="searchInput"
                value={searchKeyword}
                onChange={handleInputChange}
              />
              <span className="searchIcon">
                <CiSearch />
              </span>
            </span>
            <span className="importButton" onClick={handleImportButtonClick}>
              <span className="title">Import CSV</span>
              <span className="csvIcon">
                <BsFiletypeCsv />
              </span>
            </span>
            <span className="createButton" onClick={handleCreateButtonClick}>
              <span className="title">Create Student</span>
              <span className="addIcon">
                <IoIosAddCircle />
              </span>
            </span>
          </span>
        </div>
        <div className="filterForm">
          <CustomForm
            createButtonLabel="Search"
            flexDirection="row"
            showDefaultHeader={false}
            fields={filterFields}
            showCancelButton={false}
            showCloseButton={false}
            showCreateButton={false}
          />
        </div>
        <div className="bottom">
          <table className="listTable">
            <thead>
              <tr className="tableHeaderRow">
                <th></th>
                <th>
                  {formData.enrolledSemester && (
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      className="checkBox"
                    />
                  )}
                </th>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 ? (
                rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td
                      style={{ position: "relative" }}
                      ref={(el) => (menuRefs.current[rowIndex] = el)}
                    >
                      <IoIosMore onClick={() => handleMoreClick(rowIndex)} />
                      {visibleMenu === rowIndex && (
                        <OptionsMenu
                          menuItems={getMenuItems(row)}
                          visible={true}
                        />
                      )}
                    </td>
                    <td>
                      {formData.enrolledSemester && (
                        <input
                          type="checkbox"
                          checked={selectedStudents.includes(row.uuid)}
                          onChange={(e) =>
                            handleSelectStudent(row.uuid, e.target.checked)
                          }
                          className="checkBox"
                        />
                      )}
                    </td>
                    {Array.isArray(row.displayData) &&
                      row.displayData.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={headers.length + 1}
                    style={{ fontSize: "14px", borderBottom: "none" }}
                  >
                    <NoRecord />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {selectedStudents.length > 0 && (
          <span onClick={handlePromoteClick} className="promoteButton">
            <span className="title"> Promote Selected Students</span>
          </span>
        )}
        {isDialogOpen && (
          <div className="dialogOverlay">
            <div className="dialogBox">
              <select
                value={promotionSemester}
                onChange={(e) => setPromotionSemester(e.target.value)}
              >
                <option value="">Select Semester</option>
                {semester.map((s) => (
                  <option key={s.uuid} value={s.uuid}>
                    {s.semester}
                  </option>
                ))}
              </select>
              <div className="dialogActions">
                <button onClick={handleDialogClose} className="cancel">
                  Cancel
                </button>
                <button onClick={handleDialogSubmit} className="submit">
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default StudentList;
