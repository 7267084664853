import React, { useEffect, useState } from "react";
import "./CreateExamRelation.scss";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeftLong } from "react-icons/fa6";

const CreateExamRelation = () => {
  const initialFormData = {
    examSetupId: "",
    subjects: [],
    fullMarks: [],
    passMarks: [],
    examDate: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [examSetup, setExamSetup] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchExamSetup = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/examSetup/list`);
        setExamSetup(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamSetup();
  }, []);

  useEffect(() => {
    if (formData.examSetupId) {
      const fetchSubject = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/examSetup/viewSubjects`,
            {
              id: formData.examSetupId,
            }
          );
          setSubjects(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.log(error);
        }
      };
      fetchSubject();
    }
  }, [formData.examSetupId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/examsubject/relation/create`, {
          exam_setup_id: formData.examSetupId,
          subjects: subjects.map((subject, index) => ({
            subject: subject.name,
            fullMark: formData.fullMarks[index],
            passMark: formData.passMarks[index],
            examDate: formData.examDate[index],
          })),
        }),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message);
      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleFormChange = (event, index, field) => {
    const { value } = event.target;

    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };

      if (field === "fullMark") {
        updatedFormData.fullMarks[index] = Number(value);
      } else if (field === "passMark") {
        updatedFormData.passMarks[index] = Number(value);
      } else if (field === "examDate") {
        updatedFormData.examDate[index] = value;
      }

      return updatedFormData;
    });
  };

  return (
    <div className="createExamSubContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Assign Exam to Subject</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit}>
          <div className="examWrapper">
            <div className="inputGroup">
              <label htmlFor="exam">Exam</label>
              <select
                name="examSetupId"
                value={formData.examSetupId}
                onChange={(e) =>
                  setFormData({ ...formData, examSetupId: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Exam
                </option>
                {examSetup.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {formData.examSetupId && subjects.length > 0 && (
            <div className="subjectWrapper">
              <table className="subjectInputGroup">
                <thead>
                  <tr>
                    <th>Subject</th>
                    <th>Full Mark</th>
                    <th>Pass Mark</th>
                    <th>Exam Date</th>
                  </tr>
                </thead>
                <tbody>
                  {subjects.map((subject, index) => (
                    <tr key={index}>
                      <td>
                        <label>{subject.name}</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          value={formData.fullMarks[index]}
                          onChange={(e) =>
                            handleFormChange(e, index, "fullMark")
                          }
                          placeholder="Full Mark"
                          min={0}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={formData.passMarks[index]}
                          onChange={(e) =>
                            handleFormChange(e, index, "passMark")
                          }
                          placeholder="Pass Mark"
                          min={0}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={formData.examDate[index]}
                          onChange={(e) =>
                            handleFormChange(e, index, "examDate")
                          }
                          placeholder="Exam Date"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </form>
        <div className="submitWrapper">
          <button type="submit" className="create-btn" onClick={handleSubmit}>
            Submit
          </button>
          <button
            type="submit"
            className="cancel-btn "
            onClick={handleBackClick}
          >
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateExamRelation;
