import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import "./CreateResult.scss";
import { BASE_URL } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeftLong } from "react-icons/fa6";

const CreateResult = () => {
  const initialFormData = {
    examUuid: "",
    studentData: [],
    subjects: [],
  };

  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [exams, setExams] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMarksChange = (e, studentIndex, subjectIndex) => {
    const updatedStudents = [...formData.studentData];
    if (!updatedStudents[studentIndex].subjects) {
      updatedStudents[studentIndex].subjects = formData.subjects.map(
        (subject) => ({
          name: subject.name,
          obtainedMarks: "",
        })
      );
    }

    updatedStudents[studentIndex].subjects[subjectIndex].obtainedMarks =
      e.target.value;

    setFormData({ ...formData, studentData: updatedStudents });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      examSetupUuid: formData.examUuid,
      studentsData: formData.studentData.map((student) => ({
        studentUuid: student.studentUuid,
        subjects: student.subjects.map((subject) => ({
          name: subject.name,
          obtainedMarks: subject.obtainedMarks,
        })),
      })),
    };

    try {
      const response = await adminRequest.post(
        `${BASE_URL}/result/create`,
        requestData
      );
      toast.success(response.data.message);
      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (formData.examUuid) {
      const fetchSubject = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/examSubject/relation/list/getByExamSetup`,
            { exam_setup_uuid: formData.examUuid }
          );
          setSubjects(response.data.data);
          setFormData((prevState) => ({
            ...prevState,
            subjects: response.data.data.map((subject) => ({
              name: subject.subject,
              fullMark: subject.fullMark,
              passMark: subject.passMark,
            })),
            studentData: students.map((student) => ({
              studentUuid: student.uuid,
              fullName: student.fullName,
              subjects: response.data.data.map((subject) => ({
                name: subject.subject,
                obtainedMarks: "",
              })),
            })),
          }));
          updateAuthToken();
        } catch (error) {
          console.error(error);
        }
      };
      fetchSubject();
    }
  }, [formData.examUuid, students]);

  useEffect(() => {
    if (formData.examUuid) {
      const fetchStudent = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/examSetup/viewStudents`,
            { exam_setup_uuid: formData.examUuid }
          );
          setStudents(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.error(error);
        }
      };
      fetchStudent();
    }
  }, [formData.examUuid]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/examSetup/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchExam();
  }, []);

  return (
    <div className="createResultContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Result</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit}>
          <div className="studentWrapper">
            <div className="inputGroup">
              <label htmlFor="examUuid">Exam</label>
              <select
                name="examUuid"
                value={formData.examUuid}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Exam
                </option>
                {exams.map((option, index) => (
                  <option value={option.uuid} key={index}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {formData.studentData.length > 0 && subjects.length > 0 && (
            <div className="marksWrapper">
              <span>Marks Ledger</span>
              <table border={1} className="subjectInputGroup">
                <thead>
                  <tr>
                    <th rowSpan={2}>Student Name</th>
                    {subjects.map((subject, index) => (
                      <th key={index} colSpan={2} className="subject">
                        {subject.subject}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {subjects.map((subject, index) => (
                      <>
                        <th key={`fullMark-${index}`} className="smallCol">
                          Full Mark: {subject.fullMark}
                        </th>
                        <th key={`passMark-${index}`} className="smallCol">
                          Pass Mark: {subject.passMark}
                        </th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {formData.studentData.map((student, studentIndex) => (
                    <tr key={studentIndex}>
                      <td>{student.fullName}</td>
                      {subjects.map((subject, subjectIndex) => (
                        <td key={`${studentIndex}-${subjectIndex}`} colSpan={2}>
                          <input
                            type="number"
                            value={
                              student.subjects[subjectIndex]?.obtainedMarks
                            }
                            onChange={(e) =>
                              handleMarksChange(e, studentIndex, subjectIndex)
                            }
                            placeholder="Obtained Marks"
                            min={0}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {formData.studentData.length > 0 && subjects.length > 0 && (
            <div className="submitWrapper">
              <button type="submit" className="create-btn">
                Submit
              </button>
              <button
                type="button"
                className="cancel-btn"
                onClick={handleBackClick}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default CreateResult;
