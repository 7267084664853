import React, { useEffect, useState } from "react";
import "./ViewNotice.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import { FaArrowLeftLong } from "react-icons/fa6";
import { AiOutlineTag } from "react-icons/ai";

const ViewNotice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[4];
  const [data, setData] = useState(null); // Initially set to null

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchNotice = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/notice/view`, {
          uuid: activeURL,
        });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotice();
  }, [activeURL]);

  if (!data) {
    return <Loader />;
  }
  const { notice: noticeData } = data;

  const { title, date, desc, fileURL, status } = noticeData;

  return (
    <div className="viewNoticeContainer">
      <div className="contentWrapper">
        <div className="noticeContent">
          <div className="heading">
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            <span className="title">{title}</span>
            <span className="status">{status}</span>
          </div>
          <div className="info">
            <span className="date">Effective on: {date}</span>
            <div className="category">
              <span className="icon">
                <AiOutlineTag />
              </span>
              <span className="heading">{data.notice.NoticeCategory.name}</span>
            </div>
          </div>
          <span className="audience">
            Audience:{" "}
            {data.notice.Notifiers.map((notifier) => notifier.name).join(", ")}
          </span>
          <span className="description">{desc}</span>
          {data.notice?.fileURL && (
            <div className="imageContainer">
              <img src={`${IMG_URL}${fileURL}`} className="file" alt="notice" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewNotice;
