import React, { useEffect, useState } from "react";
import { adminRequest } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Swal from "sweetalert2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import List from "../../../components/List/List";
import Loader from "../../../components/Loader/Loader";

const EventCategoryList = () => {
  const headers = ["Name", "Color", "Status"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/event/category/list`
      );
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.name,
          <div
            style={{
              backgroundColor: e.colorCode,
              width: "50px",
              height: "20px",
            }}
            title={e.colorCode}
          />,
          e.status,
        ],
        uuid: e.uuid,
        status: e.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleBlock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to block this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/event/category/block`,
            {
              uuid: uuid,
            }
          );
          Swal.fire({
            title: "Blocked!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };
  const handleUnblock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to unblock this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/event/category/unblock`,
            {
              uuid: uuid,
            }
          );
          Swal.fire({
            title: "Unblocked!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };
  const getMenuItems = (row) => [
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.uuid);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.uuid);
          },
        },
  ];

  return (
    <div className="eventCategoryListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Event Category List"
            createButtonLabel="Create Event Category"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EventCategoryList;
