import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { PiStudent } from "react-icons/pi";
import { PiChalkboardTeacher } from "react-icons/pi";
import { LuBookMinus } from "react-icons/lu";
import { BASE_URL } from "../../utils/config";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";
import { Bar, Line, Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { MdOutlineAttachMoney } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [studentCount, setStudentCount] = useState([]);
  const [facultyCount, setFacultyCount] = useState([]);
  const [courseCount, setCourseCount] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [students, setStudents] = useState([]);
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [studentsByGender, setStudentsByGender] = useState({
    MALE: 0,
    FEMALE: 0,
    OTHERS: 0,
  });
  const [studentsByCourse, setStudentsByCourse] = useState({});
  const [enrollmentData, setEnrollmentData] = useState([]);
  const labels = Object.keys(studentsByCourse);
  const maleData = labels.map((label) => studentsByCourse[label]?.MALE);
  const femaleData = labels.map((label) => studentsByCourse[label]?.FEMALE);
  const othersData = labels.map((label) => studentsByCourse[label]?.OTHERS);

  const enrollmentLabels = enrollmentData.map((item) => item.batch);
  const data = enrollmentData.map((item) => item.count);
  const getDayOfMonth = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  const getMonthFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short" });
  };

  useEffect(() => {
    const fetchStudentCount = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/student/count`);
        setStudentCount(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentCount();
  }, []);

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/student/fetchLatest`
        );
        setStudents(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudent();
  }, []);

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/examSetup/upcomming`
        );
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchExams();
  }, []);

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/faculty/list`);
        setFaculty(response.data.faculties);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchFaculty();
  }, []);

  useEffect(() => {
    const fetchStudentByGender = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/student/demographics`
        );
        const demographics = response.data.data[0];
        setStudentsByGender(demographics);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentByGender();
  }, []);
  useEffect(() => {
    const fetchEnrollmentData = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/student/enrollmentTrend`
        );
        setEnrollmentData(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchEnrollmentData();
  }, []);

  useEffect(() => {
    const fetchStudentByCourse = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/student/demographics/course`
        );
        setStudentsByCourse(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentByCourse();
  }, []);

  useEffect(() => {
    const fetchFacultyCount = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/faculty/count`);
        setFacultyCount(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchFacultyCount();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/event/fetchLatest`
        );
        setEvents(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchEvents();
  }, []);

  useEffect(() => {
    const fetchCourseCount = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/course/count`);
        setCourseCount(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchCourseCount();
  }, []);

  const handleViewAllFaculty = () => {
    navigate("/setup/faculty");
  };
  const handleViewAllStudent = () => {
    navigate("/student");
  };

  return (
    <div className="dashboardContainer">
      <div className="dashboardContents">
        <div className="heading">Dashboard</div>
        <div className="top">
          <div className="studentBox">
            <span className="studentIcon">
              <PiStudent />
            </span>
            <span className="right">
              <span className="title">Total Students</span>
              <span className="value">{studentCount}</span>
            </span>
          </div>
          <div className="facultyBox">
            <span className="facultyIcon">
              <PiChalkboardTeacher />
            </span>
            <span className="right">
              <span className="title">Total Faculties</span>
              <span className="value">{facultyCount}</span>
            </span>
          </div>
          <div className="courseBox">
            <span className="courseIcon">
              <LuBookMinus />
            </span>
            <span className="right">
              <span className="title">Total Courses</span>
              <span className="value">{courseCount}</span>
            </span>
          </div>
          <div className="feesBox">
            <span className="feesIcon">
              <MdOutlineAttachMoney />
            </span>
            <span className="right">
              <span className="title">Fees Collection</span>
              <span className="value">Rs. 120000</span>
            </span>
          </div>
        </div>
        <div className="middleUp">
          <div className="studentBarChartContainer">
            <Bar
              height={250}
              data={{
                labels: labels,
                datasets: [
                  {
                    label: "Male",
                    data: maleData,
                    backgroundColor: "#26ce72",
                  },
                  {
                    label: "Female",
                    data: femaleData,
                    backgroundColor: "#f0b30c",
                  },
                  {
                    label: "Others",
                    data: othersData,
                    backgroundColor: "#db346c",
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Student Overview",
                    padding: "20",
                    align: "start",
                    font: {
                      size: 16,
                      weight: "600",
                    },
                    color: "#363949",
                  },
                  responsive: true,
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      stepSize: 50,
                    },
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
          <div className="studentPieChartContainer">
            <Pie
              data={{
                labels: ["Male", "Female", "Others"],
                datasets: [
                  {
                    data: [
                      studentsByGender.MALE,
                      studentsByGender.FEMALE,
                      studentsByGender.OTHERS,
                    ],
                    backgroundColor: ["#26ce72", "#f0b30c", "#db346c"],
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Demographics",
                    padding: "20",
                    align: "start",
                    font: {
                      size: 16,
                      weight: "600",
                    },
                    color: "#363949",
                  },
                  responsive: true,
                  legend: {
                    position: "bottom",
                  },
                },
              }}
            />
          </div>
          <div className="eventContainer">
            <div className="heading">Upcoming Events</div>
            {events.map((event, index) => (
              <div className="event" key={index}>
                <div className="top">
                  <span className="left">
                    <span className="day">{event.eventDay} </span>
                  </span>
                  <span className="right">
                    <span className="date">{event.eventDateLabel}</span>
                  </span>
                </div>
                <div className="bottom">
                  <span className="eventDate">
                    <span className="day">
                      {getDayOfMonth(event.eventDate)}
                    </span>
                    <span className="month">
                      {getMonthFromDate(event.eventDate)}
                    </span>
                  </span>
                  <span className="right">
                    <span className="eventName">{event.name}</span>
                    <span className="eventCategory">
                      <span className="icon">
                        <BiCategory />
                      </span>
                      <span className="category">
                        {event.eventCategory.name}
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="middleBottom">
          <div className="studentTrendContainer">
            <Line
              height={300}
              data={{
                labels: enrollmentLabels,
                datasets: [
                  {
                    label: "Student Enrollment",
                    data: data,
                    tension: 0.4,
                  },
                ],
              }}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "Student Enrollment Overview",
                    padding: "20",
                    align: "start",
                    font: {
                      size: 16,
                      weight: "600",
                    },
                    color: "#363949",
                  },
                  responsive: true,
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                },
              }}
            />
          </div>
          <div className="facultyListContainer">
            <div className="heading">
              <span className="left">Faculty List</span>
              <span className="right" onClick={handleViewAllFaculty}>
                View All
              </span>
            </div>
            <hr />
            <table className="listTable">
              <thead>
                <tr className="tableHeaderRow">
                  <th>Faculty Name</th>
                  <th>Contact</th>
                  <th>Faculty Type</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {faculty.map((fac, index) => (
                  <tr key={index}>
                    <td>{fac.name}</td>
                    <td>{fac.mobileNumber}</td>
                    <td>{fac.facultyCategory.name}</td>
                    <td>{fac.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="bottomPage">
          <div className="studentListContainer">
            <div className="heading">
              <span className="left">New Student List</span>
              <span className="right" onClick={handleViewAllStudent}>
                View All
              </span>
            </div>
            <hr />
            <table className="listTable">
              <thead>
                <tr className="tableHeaderRow">
                  <th>Student Name</th>
                  <th>Contact</th>
                  <th>Address</th>
                  <th>Program</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student, index) => (
                  <tr key={index}>
                    <td>{student.fullName}</td>
                    <td>{student.phoneNumber}</td>
                    <td>{student.address}</td>
                    <td>{student.enrolledProgram}</td>
                    <td>{student.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="examListContainer">
            <div className="heading">Exam Schedule</div>
            {exams.map((exam, index) => (
              <div key={index} className="examContainer">
                <div className="left">{exam.Curriculum.Course.short_name}</div>
                <div className="right">
                  <span className="top">{exam.name}</span>
                  <span className="bottom">
                    {exam.examStartDate} | {exam.examEndDate} |{" "}
                    {exam.Curriculum.semester}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
