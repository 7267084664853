import React, { useEffect, useRef, useState } from "react";
import "./RoutineList.scss";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import NotFound from "../../../components/NotFound/NotFound";
import { ToastContainer } from "react-toastify";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { CiSearch } from "react-icons/ci";
import { IoIosAddCircle, IoIosMore } from "react-icons/io";
import CustomForm from "../../../components/CustomForm/CustomForm";
import OptionsMenu from "../../../components/OptionsMenu/OptionsMenu";
import NoRecord from "../../../components/NoRecord/NoRecord";

const RoutineList = () => {
  const filterForm = {
    course: "",
    semester: "",
  };
  const headers = [
    "Subject",
    "Program",
    "Semester",
    "Day",
    "Start Time",
    "End Time",
    "Room",
    "Faculty",
  ];
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState(filterForm);
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState([]);
  const [semester, setSemester] = useState([]);
  const [visibleMenu, setVisibleMenu] = useState(null);
  const menuRefs = useRef([]);
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/routine/list`);
      const fetchedRows = response.data.data.map((r) => ({
        displayData: [
          r.subject,
          r.program,
          r.semester,
          r.day,
          r.startTime,
          r.endTime,
          r.room,
          r.faculty.name,
        ],
        uuid: r.uuid,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchRoutineBySemester = async () => {
    try {
      const response = await adminRequest.post(
        `${BASE_URL}/routine/viewBySemester`,
        {
          uuid: formData.semester,
        }
      );
      const fetchedRows = response.data.data.map((r) => ({
        displayData: [
          r.subject,
          r.program,
          r.semester,
          r.day,
          r.startTime,
          r.endTime,
          r.room,
          r.faculty.name,
        ],
        uuid: r.uuid,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    }
  };
  useEffect(() => {
    fetchRoutineBySemester();
  }, [formData.semester]);

  updateAuthToken();

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setCourse(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchProgram();
  }, []);

  useEffect(() => {
    if (formData.course) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            { id: formData.course }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.log(error);
        }
      };
      fetchSemester();
    }
  }, [formData.course]);

  const handleSearch = React.useCallback(
    _.debounce(async (query) => {
      if (!query) {
        fetchData();
        return;
      }
      try {
        const response = await adminRequest.post(`${BASE_URL}/routine/search`, {
          searchKeyword: query,
        });
        const fetchedRows = response.data.data.map((r) => ({
          displayData: [
            r.subject,
            r.program,
            r.semester,
            r.day,
            r.startTime,
            r.endTime,
            r.room,
            r.faculty.name,
          ],
          uuid: r.uuid,
          status: r.status,
        }));
        setRows(fetchedRows);
      } catch (error) {
        console.error(error);
      }
    }, 300),
    []
  );

  const handleCreateButtonClick = () => {
    navigate("/setup/routine/create");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    handleSearch(value);
  };

  const handleMoreClick = (rowIndex) => {
    setVisibleMenu(visibleMenu === rowIndex ? null : rowIndex);
  };

  const getMenuItems = (row) => [{ link: `edit/${row.uuid}`, text: "Edit" }];

  const filterFields = [
    {
      name: "course",
      label: "Course",
      type: "select",
      value: formData.course,
      onChange: handleChange,
      options: [
        { label: "Select Course", value: "" },
        ...course.map((c) => ({
          label: c.short_name,
          value: c.id,
        })),
      ],
    },
    {
      name: "semester",
      label: "Semester",
      type: "select",
      value: formData.semester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((s) => ({
          label: s.semester,
          value: s.uuid,
        })),
      ],
    },
  ];

  return (
    <div className="routineListContainer">
      <div className="routineListContents">
        <div className="top">
          <span className="leftTitle">
            <FaArrowLeftLong onClick={handleBackClick} className="icon" />
            Routine List
          </span>
          <span className="rightContents">
            <span className="searchField">
              <input
                type="text"
                name="search"
                placeholder="Search Routine..."
                className="searchInput"
                value={searchKeyword}
                onChange={handleInputChange}
              />
              <span className="searchIcon">
                <CiSearch />
              </span>
            </span>
            <span className="createButton" onClick={handleCreateButtonClick}>
              <span className="title">Create Routine</span>
              <span className="addIcon">
                <IoIosAddCircle />
              </span>
            </span>
          </span>
        </div>
        <div className="filterForm">
          <CustomForm
            createButtonLabel="Search"
            flexDirection="row"
            showDefaultHeader={false}
            fields={filterFields}
            showCancelButton={false}
            showCloseButton={false}
            showCreateButton={false}
          />
        </div>
        <div className="bottom">
          <table className="listTable">
            <thead>
              <tr className="tableHeaderRow">
                <th></th>
                <th></th>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 ? (
                rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td
                      style={{ position: "relative" }}
                      ref={(el) => (menuRefs.current[rowIndex] = el)}
                    >
                      <IoIosMore onClick={() => handleMoreClick(rowIndex)} />
                      {visibleMenu === rowIndex && (
                        <OptionsMenu
                          menuItems={getMenuItems(row)}
                          visible={true}
                        />
                      )}
                    </td>
                    <td></td>
                    {Array.isArray(row.displayData) &&
                      row.displayData.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={headers.length + 1}
                    style={{ fontSize: "14px", borderBottom: "none" }}
                  >
                    <NoRecord />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default RoutineList;
