import React, { useEffect, useState } from "react";
import "./ViewCourse.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import Loader from "../../../components/Loader/Loader";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import { IoIosArrowDropdown } from "react-icons/io";
import { IoIosArrowDropup } from "react-icons/io";

const ViewCourse = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [activeSemester, setActiveSemester] = useState(null);
  const location = useLocation();
  const activeURL = location.pathname.split("/")[5];

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const toggleAccordion = (index) => {
    setActiveSemester(activeSemester === index ? null : index);
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/course/view`, {
          uuid: activeURL,
        });
        setData(response.data);
      } catch (error) {}
    };
    fetchCourse();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }

  return (
    <div className="courseDetailContainer">
      <div className="courseDetailContents">
        <div className="courseDetailHeader">
          <span>
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            Course Details
          </span>
        </div>
        <div className="courseDetails">
          <div className="left">
            <span className="courseHeader">
              {data.data.name} ({data.data.short_name})
            </span>
            <span className="status">{data.data.status}</span>
            <span className="courseImgContainer">
              <img
                src={
                  data.data.image_url
                    ? `${IMG_URL}${data.data.image_url}`
                    : "https://www.pngarts.com/files/7/School-Education-Course-PNG-Image.png"
                }
                alt={data.data.name}
                className="courseImg"
              />
            </span>
          </div>
          <div className="right">
            <div className="description">
              <span className="data">{data.data.desc}</span>
            </div>
            <div className="stream">
              <span className="title">Stream: </span>
              <span className="data">{data.data.stream}</span>
            </div>
            <div className="duration">
              <span className="title">Duration: </span>
              <span className="data">{data.data.duration}</span>
            </div>
            <div className="semester">
              <span className="title">Total Semesters: </span>
              <span className="data">{data.data.semester_count}</span>
            </div>
            <div className="subject">
              <span className="title">Total Subjects: </span>
              <span className="data">{data.data.subject_count}</span>
            </div>
            <div className="seats">
              <span className="title">Total Seats: </span>
              <span className="data">{data.data.seat}</span>
            </div>
            <div className="creditHours">
              <span className="title">Credit Hours: </span>
              <span className="data">{data.data.credit_hours}</span>
            </div>
          </div>
        </div>
        <div className="semesters">
          {data.data.curriculums.map((semester, index) => (
            <div key={semester.id} className="accordionItem">
              <div
                className={`accordionHeader ${
                  activeSemester === index ? "active" : ""
                }`}
                onClick={() => toggleAccordion(index)}
              >
                <span className="semesterHeading">{semester.semester}</span>
                <span className="dropdownIcon">
                  {activeSemester === index ? (
                    <IoIosArrowDropup />
                  ) : (
                    <IoIosArrowDropdown />
                  )}
                </span>
              </div>

              {activeSemester === index && semester.subjects.length > 0 && (
                <div className="accordionContent">
                  <table>
                    <thead>
                      <tr>
                        <th>Subject Name</th>
                        <th>Subject Code</th>
                        <th>Credit Hours</th>
                      </tr>
                    </thead>
                    <tbody>
                      {semester.subjects.map((subject) => (
                        <tr key={subject.id}>
                          <td>{subject.subject_name}</td>
                          <td>{subject.subject_code}</td>
                          <td>{subject.credit_hour}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {activeSemester === index && semester.subjects.length === 0 && (
                <div className="noSubjects">
                  <p>No subjects available for this semester.</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewCourse;
