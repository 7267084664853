import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import Loader from "../../../components/Loader/Loader";

const EditResult = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[4];
  const initialFormData = {
    obtainedMarks: "",
    resultUuid: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/result/view`, {
          resultUuid: `${activeURL}`,
        });

        setData(response.data);
        setFormData({
          obtainedMarks: response.data.data.obtainedMarks,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchResult();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/result/update`, {
          resultUuid: activeURL,
          obtainedMarks: formData.obtainedMarks,
        }),
        {
          pending: "Updating Result",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "obtainedMarks",
      label: "Obtained Marks",
      type: "text",
      value: formData.obtainedMarks,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editResultContainer">
      <CustomForm
        header="Edit Marks"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditResult;
