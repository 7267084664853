import React, { useEffect, useState } from "react";
import "./ViewStudent.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL, IMG_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { FaArrowLeftLong, FaEnvelope } from "react-icons/fa6";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { BsFillCalendarDateFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa6";
import { FaSchool } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { MdAccessTimeFilled } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { trimDate } from "../../../utils/dateUtil";
import Swal from "sweetalert2";

const ViewStudent = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [refresh, setRefresh] = useState(false);

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/student/view`, {
          uuid: activeURL,
        });
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudent();
  }, [activeURL, refresh]);

  updateAuthToken();
  const handleSendResendConfirmation = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/student/resendMail`, {
          uuid: activeURL,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };
  const handleParentResendConfirmation = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/parent/resendMail`, {
          email: data.data.parentEmail,
        }),
        {
          pending: "Processing your request",
        }
      );
      setRefresh(!refresh);
      Swal.fire({
        title: "Success",
        text: `${response.data.message}`,
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "ERROR!",
        text: `${error.response.data.message}`,
        icon: "error",
      });
    }
  };

  if (!data || !data.data) {
    return <Loader />;
  }
  return (
    <div className="studentDetailContainer">
      <div className="studentDetailContents">
        <div className="studentDetailHeader">
          <span>
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            Student Details
          </span>
        </div>
        <div className="body">
          <div className="primaryDetails">
            <div className="profileImgContainer">
              <img
                src={
                  data.data.profilePicture
                    ? `${IMG_URL}${data.data.profilePicture}`
                    : "https://cdn-icons-png.flaticon.com/512/67/67902.png"
                }
                alt={data.data.fullName}
                className="profileImg"
              />
            </div>
            <div className="studentDetailsContainer">
              <span className="fullName">{data.data.fullName}</span>
              {data?.data?.lastLoginTime && (
                <div className="lastLoginContainer">
                  <span className="icon">
                    <MdAccessTimeFilled />
                  </span>

                  <span className="lastLogin">
                    Last login: {trimDate(data.data.lastLoginTime)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="bottom">
            <button
              type="button"
              className="bottom-btn"
              onClick={handleSendResendConfirmation}
            >
              Resend Student Confirmation Mail
            </button>
            <button
              type="button"
              className="bottom-btn"
              onClick={handleParentResendConfirmation}
            >
              Resend Parent Confirmation Mail
            </button>
          </div>
          <div style={{ paddingLeft: "2rem" }} className="basicDetails">
            <div className="header">Basic Information</div>
            <div className="information">
              <div className="emailContainer">
                <span className="icon">
                  <FaEnvelope />
                </span>
                <span className="email">{data.data.email}</span>
              </div>
              <div className="phoneContainer">
                <span className="icon">
                  <FaPhoneAlt />
                </span>
                <span className="phone">{data.data.phoneNumber}</span>
              </div>
              <div className="dobContainer">
                <span className="icon">
                  <BsFillCalendarDateFill />
                </span>
                <span className="dob">{data.data.dateOfBirth}</span>
              </div>
              <div className="genderContainer">
                <span className="icon">
                  <FaUser />
                </span>
                <span className="gender">{data.data.gender}</span>
              </div>
              <div className="addressContainer">
                <span className="icon">
                  <FaMapMarkerAlt />
                </span>
                <span className="address">{data.data.address}</span>
              </div>
              <div className="batchContainer">
                <span className="icon">
                  <FaGraduationCap />
                </span>
                <span className="batch">{data.data.batch}</span>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "2rem" }} className="academicDetails">
            <div className="header">Academic Information</div>
            <div className="information">
              <div className="courseContainer">
                <span className="icon">
                  <FaSchool />
                </span>
                <span className="data">{data.data.enrolledProgramName}</span>
              </div>
              <div className="semesterContainer">
                <span className="icon">
                  <FaBookOpen />
                </span>
                <span className="data">{data.data.enrolledSemesterName}</span>
              </div>
              <div className="enrollmentDateContainer">
                <span className="icon">
                  <BsFillCalendarDateFill />
                </span>
                <span className="data">
                  Enrolled: {data.data.enrollmentDate}
                </span>
              </div>
              <div className="statusContainer">
                <span className="icon">
                  <FiActivity />
                </span>
                <span className="status">{data.data.status}</span>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "2rem" }} className="parentInformation">
            <div className="header">Parent/Guardian Information</div>
            <div className="information">
              <div className="nameContainer">
                <span className="icon">
                  <FaUserCircle />
                </span>
                <span className="data">{data.data.parentName}</span>
              </div>
              <div className="emailContainer">
                <span className="icon">
                  <FaEnvelope />
                </span>
                <span className="data">{data.data.parentEmail}</span>
              </div>
              <div className="phoneContainer">
                <span className="icon">
                  <FaPhoneAlt />
                </span>
                <span className="data">{data.data.parentPhone}</span>
              </div>
            </div>
          </div>
          <div style={{ paddingLeft: "2rem" }} className="systemInformation">
            <div className="header">System Information</div>
            <div className="information">
              <div className="createdDateContainer">
                <span className="icon">
                  <MdAccessTimeFilled />
                </span>
                <span className="data">
                  Created: {trimDate(data.data.createdAt)}
                </span>
              </div>
              <div className="updatedDateContainer">
                <span className="icon">
                  <MdAccessTimeFilled />
                </span>
                <span className="data">
                  Updated: {trimDate(data.data.updatedAt)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ViewStudent;
