import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";
import NotFound from "../../../components/NotFound/NotFound";
import "./FacultyList.scss";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { CiSearch } from "react-icons/ci";
import { IoIosAddCircle, IoIosMore } from "react-icons/io";
import OptionsMenu from "../../../components/OptionsMenu/OptionsMenu";
import NoRecord from "../../../components/NoRecord/NoRecord";

const FacultyList = () => {
  const headers = [
    "Name",
    "Faculty Type",
    "Mobile Number",
    "Qualification",
    "Specialization",
    "Hired Date",
    "Status",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleMenu, setVisibleMenu] = useState(null);
  const menuRefs = useRef([]);
  const navigate = useNavigate();
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSearch = React.useCallback(
    _.debounce(async (query) => {
      if (!query) {
        fetchData();
        return;
      }
      try {
        const response = await adminRequest.post(`${BASE_URL}/faculty/search`, {
          searchKeyword: query,
        });
        const fetchedRows = response.data.data.map((f) => ({
          displayData: [
            f.name,
            f.facultyCategory.name,
            f.mobileNumber,
            f.qualification,
            f.specialization,
            f.hire_date,
            f.status,
          ],
          uuid: f.uuid,
          status: f.status,
        }));
        setRows(fetchedRows);
      } catch (error) {
        console.error(error);
      }
    }, 300),
    []
  );

  const handleCreateButtonClick = () => {
    navigate("/setup/faculty/create");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    handleSearch(value);
  };

  const handleMoreClick = (rowIndex) => {
    setVisibleMenu(visibleMenu === rowIndex ? null : rowIndex);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/faculty/list`);
      const fetchedRows = response.data.faculties.map((f) => ({
        displayData: [
          f.name,
          f.facultyCategory.name,
          f.mobileNumber,
          f.qualification,
          f.specialization,
          f.hire_date,
          f.status,
        ],
        uuid: f.uuid,
        status: f.status,
      }));
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setActive = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to activate this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "SetActive",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/setActive`,
            {
              uuid: uuid,
            }
          );
          Swal.fire({
            title: "Activated!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const setLeave = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to set on leave this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "SetLeave",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/setLeave`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "On Leave!",
            text: `${response.data.message}`,
            icon: "success",
          });
          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const setRetire = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to retire this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Retire",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/retire`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "Retired!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };
  const setTerminate = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to terminate this faculty?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Terminate",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/faculty/terminate`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "Terminated!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const getMenuItems = (row) => [
    { link: `view/${row.uuid}`, text: "View" },
    { link: `edit/${row.uuid}`, text: "Edit" },
    {
      link: "#",
      text: "Retire",
      onClick: (e) => {
        e.preventDefault();
        setRetire(row.uuid);
      },
    },
    {
      link: "#",
      text: "Terminate",
      onClick: (e) => {
        e.preventDefault();
        setTerminate(row.uuid);
      },
    },
    {
      link: "#",
      text: "SetLeave",
      onClick: (e) => {
        e.preventDefault();
        setLeave(row.uuid);
      },
    },
    {
      link: "#",
      text: "SetActive",
      onClick: (e) => {
        e.preventDefault();
        setActive(row.uuid);
      },
    },
  ];

  return (
    <div className="facultyListContainer">
      <div className="facultyListContents">
        <div className="top">
          <span className="leftTitle">
            <FaArrowLeftLong onClick={handleBackClick} className="icon" />
            Faculty List
          </span>
          <span className="rightContents">
            <span className="searchField">
              <input
                type="text"
                name="search"
                placeholder="Search Faculty..."
                className="searchInput"
                value={searchKeyword}
                onChange={handleInputChange}
              />
              <span className="searchIcon">
                <CiSearch />
              </span>
            </span>
            <span className="createButton" onClick={handleCreateButtonClick}>
              <span className="title">Create Faculty</span>
              <span className="addIcon">
                <IoIosAddCircle />
              </span>
            </span>
          </span>
        </div>
        <div className="bottom">
          <table className="listTable">
            <thead>
              <tr className="tableHeaderRow">
                <th></th>
                <th></th>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows && rows.length > 0 ? (
                rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td
                      style={{ position: "relative" }}
                      ref={(el) => (menuRefs.current[rowIndex] = el)}
                    >
                      <IoIosMore onClick={() => handleMoreClick(rowIndex)} />
                      {visibleMenu === rowIndex && (
                        <OptionsMenu
                          menuItems={getMenuItems(row)}
                          visible={true}
                        />
                      )}
                    </td>
                    <td></td>
                    {Array.isArray(row.displayData) &&
                      row.displayData.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={headers.length + 1}
                    style={{ fontSize: "14px", borderBottom: "none" }}
                  >
                    <NoRecord />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default FacultyList;
