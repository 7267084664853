import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import Loader from "../../../components/Loader/Loader";

const EditStudent = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const initialFormData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    batch: "",
    enrollmentDate: "",
    parentFullName: "",
    parentEmail: "",
    parentPhoneNumber: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/student/view`, {
          uuid: `${activeURL}`,
        });
        setData(response.data);
        setFormData({
          fullName: response.data.data.fullName,
          email: response.data.data.email,
          phoneNumber: response.data.data.phoneNumber,
          dateOfBirth: response.data.data.dateOfBirth,
          address: response.data.data.address,
          batch: response.data.data.batch,
          enrollmentDate: response.data.data.enrollmentDate,
          parentFullName: response.data.data.parentName,
          parentEmail: response.data.data.parentEmail,
          parentPhoneNumber: response.data.data.parentPhone,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudent();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/student/update`, {
          uuid: activeURL,
          fullName: formData.fullName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          dateOfBirth: formData.dateOfBirth,
          address: formData.address,
          batch: formData.batch,
          enrollmentDate: formData.enrollmentDate,
          parentFullName: formData.parentFullName,
          parentEmail: formData.parentEmail,
          parentPhoneNumber: formData.parentPhoneNumber,
        }),
        {
          pending: "Updating student",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "fullName",
      label: "Student Name",
      type: "text",
      value: formData.fullName,
      onChange: handleChange,
    },

    {
      name: "email",
      label: "Student Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "phoneNumber",
      label: "Student Phone Number",
      type: "text",
      value: formData.phoneNumber,
      onChange: handleChange,
    },
    {
      name: "parentFullName",
      label: "Parent Name",
      type: "text",
      value: formData.parentFullName,
      onChange: handleChange,
    },
    {
      name: "parentEmail",
      label: "Parent Email",
      type: "email",
      value: formData.parentEmail,
      onChange: handleChange,
    },
    {
      name: "parentPhoneNumber",
      label: "Parent Phone Number",
      type: "text",
      value: formData.parentPhoneNumber,
      onChange: handleChange,
    },
    {
      name: "dateOfBirth",
      label: "Date Of Birth",
      type: "date",
      value: formData.dateOfBirth,
      onChange: handleChange,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "batch",
      label: "Batch",
      type: "text",
      value: formData.batch,
      onChange: handleChange,
    },

    {
      name: "enrollmentDate",
      label: "Enrolled Date",
      type: "date",
      value: formData.enrollmentDate,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editStudentContainer">
      <CustomForm
        header="Edit Student"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditStudent;
