import React, { useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const ImportStudent = () => {
  const initialFormData = {
    file: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("file", formData.file);

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/student/uploadData`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );
      Swal.fire({
        title: "Succeed",
        text: `${response.data?.message}`,
        icon: "success",
      });
      setFormData(initialFormData);
    } catch (error) {
      Swal.fire({
        title: "Failed!",
        text: `${error.response.data?.message}`,
        icon: "error",
      });
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "file",
      label: "Upload CSV File",
      type: "file",
      value: formData.file,
      onChange: handleFileChange,
    },
  ];

  return (
    <div className="importStudentContainer">
      <CustomForm
        header="Import Student"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Import"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ImportStudent;
