import React, { useEffect, useState } from "react";
import "./SystemConfig.scss";
import { BASE_URL } from "../../utils/config";
import { adminRequest, updateAuthToken } from "../../utils/requestMethods";
import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "../../components/Card/Card";
import { MdEventAvailable } from "react-icons/md";
import { LuUserPen } from "react-icons/lu";
import { MdOutlineNoteAlt } from "react-icons/md";
import { FaArrowLeftLong } from "react-icons/fa6";

const iconMapping = {
  LuUserPen,
  MdEventAvailable,
  MdOutlineNoteAlt,
};
const SystemConfig = () => {
  const [systemConfig, setSystemConfig] = useState([]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchSetting = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/systemConfiguration/navbar`
        );
        if (isMounted) {
          updateAuthToken();
          setSystemConfig(response.data);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    };
    fetchSetting();
    return () => {
      isMounted = false;
    };
  }, []);
  if (!systemConfig) return <Loader />;
  if (!systemConfig || !systemConfig.navbar) return <Loader />;

  return (
    <div className="settingPageContainer">
      <div className="settingPageContents">
        <div className="headerTitle">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong />
          </span>
          <span className="heading">System Configuration</span>
        </div>
        <div className="settingsCard">
          {systemConfig.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`/settings/systemConfiguration${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default SystemConfig;
