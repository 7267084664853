import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import Loader from "../../../components/Loader/Loader";

const EditFaculty = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[4];
  const initialFormData = {
    name: "",
    email: "",
    mobileNumber: "",
    qualification: "",
    specialization: "",
    hire_date: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/faculty/viewOne`,
          {
            uuid: `${activeURL}`,
          }
        );
        setData(response.data);
        setFormData({
          name: response.data.faculty.name,
          email: response.data.faculty.email,
          mobileNumber: response.data.faculty.mobileNumber,
          qualification: response.data.faculty.qualification,
          specialization: response.data.faculty.specialization,
          hire_date: response.data.faculty.hire_date,
          status: response.data.faculty.status,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchFaculty();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.faculty) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/faculty/update`, {
          uuid: activeURL,
          name: formData.name,
          email: formData.email,
          mobileNumber: formData.mobileNumber,
          qualification: formData.qualification,
          specialization: formData.specialization,
          hire_date: formData.hire_date,
        }),
        {
          pending: "Updating faculty",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      value: formData.mobileNumber,
      onChange: handleChange,
    },
    {
      name: "qualification",
      label: "Qualification",
      type: "text",
      value: formData.qualification,
      onChange: handleChange,
    },
    {
      name: "specialization",
      label: "Specialization",
      type: "text",
      value: formData.specialization,
      onChange: handleChange,
    },
    {
      name: "hire_date",
      label: "Hire Date",
      type: "date",
      value: formData.hire_date,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editFacultyContainer">
      <CustomForm
        header="Edit Faculty"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditFaculty;
