import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import "./ResultList.scss";
import { BASE_URL } from "../../../utils/config";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoIosAddCircle } from "react-icons/io";

const ResultList = () => {
  const initialFormData = {
    examUuid: "",
    studentData: [],
    subjects: [],
  };

  const [subjects, setSubjects] = useState([]);
  const [students, setStudents] = useState([]);
  const [exams, setExams] = useState([]);
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const [data, setData] = useState({ data: [] });
  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleCreateClick = () => {
    navigate("/setup/result/create");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!formData.examUuid) return;
      try {
        const response = await adminRequest.post(`${BASE_URL}/result/list`, {
          examSetupUuid: formData.examUuid,
        });
        setData(response.data || { data: [] });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [formData.examUuid, activeURL]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleMarksChange = (e, studentIndex, subjectIndex) => {
    const updatedStudents = [...formData.studentData];
    updatedStudents[studentIndex].subjects[subjectIndex].obtainedMarks =
      e.target.value;
    setFormData({ ...formData, studentData: updatedStudents });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      examSetupUuid: formData.examUuid,
      studentsData: formData.studentData.map((student) => ({
        studentUuid: student.uuid,
        subjects: student.subjects.map((subject) => ({
          name: subject.name,
          obtainedMarks: subject.obtainedMarks,
        })),
      })),
    };

    try {
      const response = await adminRequest.post(
        `${BASE_URL}/result/update`,
        requestData
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message);
    }
  };

  useEffect(() => {
    const fetchSubject = async () => {
      if (!formData.examUuid) return;

      try {
        const response = await adminRequest.post(
          `${BASE_URL}/examSubject/relation/list/getByExamSetup`,
          { exam_setup_uuid: formData.examUuid }
        );
        setSubjects(response.data?.data || []);
        setFormData((prevState) => ({
          ...prevState,
          subjects:
            response.data?.data.map((subject) => ({
              name: subject.subject,
              fullMark: subject.fullMark,
              passMark: subject.passMark,
            })) || [],
        }));
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchSubject();
  }, [formData.examUuid]);

  useEffect(() => {
    const fetchStudent = async () => {
      if (!formData.examUuid) return;

      try {
        const response = await adminRequest.post(
          `${BASE_URL}/examSetup/viewStudents`,
          { exam_setup_uuid: formData.examUuid }
        );
        setStudents(response.data?.data || []);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudent();
  }, [formData.examUuid]);

  useEffect(() => {
    if (data.data.length > 0 && students.length > 0) {
      const updatedStudents = students.map((student) => {
        const studentResults = data.data.find(
          (result) => result.studentName === student.fullName
        );

        if (studentResults) {
          student.subjects = studentResults.results.map((result) => ({
            name: result.subject,
            obtainedMarks: result.obtainedMarks,
          }));
        }

        return student;
      });

      setFormData((prevState) => ({
        ...prevState,
        studentData: updatedStudents,
      }));
    }
  }, [data, students]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/examSetup/list`);
        setExams(response.data?.data || []);
        updateAuthToken();
      } catch (error) {
        console.error(error);
      }
    };
    fetchExam();
  }, []);

  return (
    <div className="resultListContainer">
      <div className="top">
        <div className="heading">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong aria-label="Go Back" />
          </span>
          <span className="headerTitle">View Result</span>
        </div>
        <div className="createButton" onClick={handleCreateClick}>
          <span className="title">Create Result</span>
          <span className="addIcon">
            <IoIosAddCircle aria-label="Add Result" />
          </span>
        </div>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit}>
          <div className="studentWrapper">
            <div className="inputGroup">
              <label htmlFor="examUuid">Select Exam</label>
              <select
                id="examUuid"
                name="examUuid"
                value={formData.examUuid || ""}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Exam
                </option>
                {exams.map((option) => (
                  <option value={option.uuid} key={option.uuid}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {formData.studentData?.length > 0 && subjects?.length > 0 && (
            <div className="marksWrapper">
              <span>Marks Ledger</span>
              <table border={1} className="subjectInputGroup">
                <thead>
                  <tr>
                    <th rowSpan={2}>Student Name</th>
                    {subjects.map((subject) => (
                      <th key={subject.id} colSpan={2} className="subject">
                        {subject.subject}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {subjects.map((subject) => (
                      <React.Fragment key={subject.id}>
                        <th className="smallCol">
                          Full Mark: {subject.fullMark}
                        </th>
                        <th className="smallCol">
                          Pass Mark: {subject.passMark}
                        </th>
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {formData.studentData.map((student, studentIndex) => (
                    <tr key={student.id || studentIndex}>
                      <td>{student.fullName}</td>
                      {student.subjects.map((subject, subjectIndex) => (
                        <td
                          key={`${student.id || studentIndex}-${
                            subject.id || subjectIndex
                          }`}
                          colSpan={2}
                        >
                          <input
                            type="number"
                            value={subject.obtainedMarks || ""}
                            onChange={(e) =>
                              handleMarksChange(e, studentIndex, subjectIndex)
                            }
                            placeholder="Obtained Marks"
                            min={0}
                            aria-label={`Marks for ${student.fullName} in ${subject.subject}`}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {formData.studentData?.length > 0 && subjects?.length > 0 && (
            <div className="submitWrapper">
              <button type="submit" className="create-btn">
                Update
              </button>
              <button
                type="button"
                className="cancel-btn"
                onClick={handleBackClick}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default ResultList;
