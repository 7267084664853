import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import Loader from "../../../components/Loader/Loader";

const EditEvent = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[4];
  const initialFormData = {
    name: "",
    eventDate: "",
    eventEndDate: null,
    eventTime: null,
    eventCategoryId: "",
    location: null,
    description: "",
    poster: null,
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventCategory, setEventCategory] = useState([]);

  useEffect(() => {
    const fetchEventCategory = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/event/category/listActive`
        );
        setEventCategory(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchEventCategory();
  }, []);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/event/view`, {
          slug: `${activeURL}`,
        });
        setData(response.data);
        setFormData({
          name: response.data.data.name,
          eventDate: response.data.data.eventDate,
          eventEndDate: response.data.data.eventEndDate,
          eventTime: response.data.data.eventTime,
          eventCategoryId: response.data.data.eventCategory.id,
          location: response.data.data.location,
          description: response.data.data.description,
          poster: response.data.data.poster,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchEvent();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, poster: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    formDataToSend.append("poster", formData.poster);

    formDataToSend.append(
      "event",
      JSON.stringify({
        slug: activeURL,
        name: formData.name,
        eventDate: formData.eventDate,
        eventEndDate: formData.eventEndDate,
        eventTime: formData.eventTime,
        event_category_id: formData.eventCategoryId,
        location: formData.location,
        description: formData.description,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/event/update`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Event Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "eventDate",
      label: "Event Date",
      type: "date",
      value: formData.eventDate,
      onChange: handleChange,
    },
    {
      name: "eventEndDate",
      label: "Event End Date",
      type: "date",
      value: formData.eventEndDate,
      onChange: handleChange,
    },
    {
      name: "eventTime",
      label: "Event Time",
      type: "time",
      value: formData.eventTime,
      onChange: handleChange,
    },
    {
      name: "eventCategoryId",
      label: "Event Category",
      type: "select",
      value: formData.eventCategoryId,
      onChange: handleChange,
      options: [
        { label: "Select Event Category", value: "" },
        ...eventCategory.map((ec) => ({
          label: ec.name,
          value: ec.id,
        })),
      ],
    },
    {
      name: "location",
      label: "Location",
      type: "text",
      value: formData.location,
      onChange: handleChange,
    },
    {
      name: "poster",
      label: "Poster",
      type: "file",
      onChange: handleImageChange,
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: formData.description,
      onChange: handleChange,
    },
  ];
  return (
    <div className="editEventContainer">
      <CustomForm
        header="Edit Event"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditEvent;
