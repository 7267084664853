import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";

const EditExamConfig = () => {
  const initialFormData = {
    name: "",
    examId: "",
  };
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[5];
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exams, setExams] = useState([]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/exam/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchExam();
  }, []);

  useEffect(() => {
    const fetchExamConfig = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/examSetup/viewOne`,
          {
            uuid: `${activeURL}`,
          }
        );
        setFormData({
          name: response.data.data.name,
          examId: response.data.data.examId,
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamConfig();
  }, [activeURL]);

  updateAuthToken();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/examSetup/update`, {
          uuid: activeURL,
          name: formData.name,
          examId: formData.examId,
        }),
        {
          pending: "Updating Exam",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "examId",
      label: "Exam",
      type: "select",
      value: formData.examId,
      onChange: handleChange,
      options: [
        { label: "Select Exam", value: "" },
        ...exams.map((e) => ({
          label: e.name,
          value: e.id,
        })),
      ],
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editExamConfigContainer">
      <CustomForm
        header="Edit Exam Config"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditExamConfig;
