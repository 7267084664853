import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import List from "../../../components/List/List";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const SubjectList = () => {
  const [rows, setRows] = useState([]);
  const headers = ["Subject Name", "Subject Code", "Credit Hour", "Status"];
  const initialFormData = {
    semester: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [semester, setSemester] = useState([]);
  const location = useLocation();
  const activeURL = location.pathname.split("/")[4];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchSemester = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/course/getSemesters`,
          {
            uuid: activeURL,
          }
        );
        setSemester(response.data.data);
      } catch (error) {}
    };
    fetchSemester();
  }, [activeURL]);

  const fetchData = async () => {
    try {
      const semesterUuid =
        formData.semester || (semester[0] && semester[0].uuid);
      if (!semesterUuid) return;
      const response = await adminRequest.post(
        `${BASE_URL}/course/list/subject`,
        {
          uuid: semesterUuid,
        }
      );
      const fetchedRows = response.data.data.map((s) => ({
        displayData: [s.name, s.subject_code, s.credit_hour, s.status],
        uuid: s.uuid,
        status: s.status,
      }));
      setRows(fetchedRows);
    } catch (error) {}
  };
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    if (formData.semester || semester.length > 0) {
      fetchData();
    }
  }, [formData.semester, semester]);

  const filterFields = [
    {
      name: "semester",
      label: "Semester",
      type: "select",
      value: formData.semester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((s) => ({
          label: s.semester,
          value: s.uuid,
        })),
      ],
    },
  ];

  const handleBlock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to block this subject?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/subject/block`,
            {
              uuid: uuid,
            }
          );

          Swal.fire({
            title: "Blocked!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const handleUnblock = async (uuid) => {
    Swal.fire({
      title: "Are you sure you want to unblock this subject?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/subject/unblock`,
            {
              uuid: uuid,
            }
          );
          Swal.fire({
            title: "Unblocked!",
            text: `${response.data.message}`,
            icon: "success",
          });
          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `edit/${row.uuid}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.uuid);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.uuid);
          },
        },
  ];
  return (
    <div className="subjectListContainer">
      <List
        title="Subject List"
        createButtonLabel="Create Subject"
        headers={headers}
        rows={rows.map((row) => row.displayData)}
        link="create"
        showEyeViewIcon={false}
        showFilterForm={true}
        filterFields={filterFields}
        onFilterSubmit={handleFilterSubmit}
        getMenuItems={(row) =>
          getMenuItems(rows.find((r) => r.displayData === row))
        }
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default SubjectList;
