import React, { useState } from "react";
import "./Exam.scss";
import Card from "../../components/Card/Card";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { PiNewspaperClipping } from "react-icons/pi";
import { PiExam } from "react-icons/pi";
import { IoNewspaperOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";

const iconMapping = {
  PiNewspaperClipping,
  PiExam,
  IoNewspaperOutline,
};

const Exam = () => {
  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const [exam] = useState({
    navbar: [
      {
        name: "Exam",
        link: "/exams",
        icon: "PiExam",
      },
      {
        name: "Exam Config",
        link: "/examConfig",
        icon: "IoNewspaperOutline",
      },
      {
        name: "Configure Exam Subject",
        link: "/examRelation",
        icon: "PiNewspaperClipping",
      },
    ],
  });

  return (
    <div className="examPageContainer">
      <div className="examPageContents">
        <div className="headerTitle">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          <span className="title"> Exam Configuration</span>
        </div>
        <div className="examCard">
          {exam.navbar.map((item, index) => {
            const IconComponent = iconMapping[item.icon];
            return (
              <Link
                to={`/setup/exam${item.link}`}
                style={{ textDecoration: "none" }}
                key={index}
              >
                <Card
                  icon={IconComponent ? <IconComponent /> : null}
                  title={item.name}
                />
              </Link>
            );
          })}
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Exam;
