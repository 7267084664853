import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";

const CreateGallery = () => {
  const initialFormData = {
    title: "",
    desc: "",
    images: [],
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    const images = Array.from(e.target.files);
    setFormData({ ...formData, images });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();

    formData.images.forEach((file) => {
      formDataToSend.append("images", file);
    });

    formDataToSend.append(
      "gallery",

      JSON.stringify({
        title: formData.title,
        desc: formData.desc,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/gallery/create`, formDataToSend),
        {
          pending: "Creating Gallery",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
      width: "500px",
      height: "30px",
    },

    {
      name: "desc",
      label: "Description",
      type: "textarea",
      value: formData.desc,
      onChange: handleChange,
      width: "500px",
      height: "150px",
    },
    {
      name: "images",
      label: "Images",
      type: "file",
      multiple: true,
      onChange: handleImageChange,
    },
  ];

  return (
    <div className="createGalleryContainer">
      <CustomForm
        header="Create Gallery"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Post"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateGallery;
