import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";

const EditUser = () => {
  const location = useLocation();
  const activeURL = location.pathname.split("/")[3];
  const initialFormData = {
    fullName: "",
    email: "",
    address: "",
    mobileNumber: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await adminRequest.post(`${BASE_URL}/user/viewUser`, {
          uuid: `${activeURL}`,
        });

        setData(response.data);
        setFormData({
          fullName: response.data.user.fullName,
          email: response.data.user.email,
          address: response.data.user.address,
          mobileNumber: response.data.user.mobileNumber,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.user) {
    return <Loader />;
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/user/update`, {
          uuid: activeURL,
          fullName: formData.fullName,
          email: formData.email,
          address: formData.address,
          mobileNumber: formData.mobileNumber,
        }),
        {
          pending: "Updating User",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "fullName",
      label: "Name",
      type: "text",
      value: formData.fullName,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      value: formData.mobileNumber,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editUserContainer">
      <CustomForm
        header="Edit User"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Update"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditUser;
