import React, { useEffect, useState } from "react";
import "./CreateNotice.scss";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

const CreateNotice = () => {
  const initialFormData = {
    title: "",
    desc: "",
    date: "",
    noticeCategoryId: "",
    file: null,
    notifer: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noticeCategory, setNoticeCategory] = useState([]);
  const [notifier, setNotifier] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  const handleNotifierChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setFormData({
        ...formData,
        notifer: [...formData.notifer, { name: value }],
      });
    } else {
      setFormData({
        ...formData,
        notifer: formData.notifer.filter((notifier) => notifier.name !== value),
      });
    }
  };

  useEffect(() => {
    const fetchNoticeCateogry = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/notice/category/listActive`
        );
        setNoticeCategory(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchNoticeCateogry();
  }, []);

  useEffect(() => {
    const fetchNotifier = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/notifier/listActive`
        );
        setNotifier(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchNotifier();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("file", formData.file);

    formDataToSend.append(
      "notice",
      JSON.stringify({
        title: formData.title,
        desc: formData.desc,
        date: formData.date,
        noticeCategoryId: formData.noticeCategoryId,
        notifier: formData.notifer,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/notice/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="createNoticeContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Notice</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit}>
          <div className="noticeWrapper">
            <div className="inputGroup">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                id="title"
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="noticeCategoryId">Notice Category</label>
              <select
                name="noticeCategoryId"
                value={formData.noticeCategoryId}
                onChange={handleChange}
                id="noticeCategoryId"
              >
                <option value="" disabled>
                  Select Notice Category
                </option>
                {noticeCategory.map((nc) => (
                  <option key={nc.id} value={nc.id}>
                    {nc.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputGroup">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                id="date"
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="file">File</label>
              <input
                type="file"
                name="file"
                onChange={handleImageChange}
                id="file"
              />
            </div>
            <div className="inputGroup">
              <label htmlFor="desc">Description</label>
              <textarea
                name="desc"
                value={formData.desc}
                onChange={handleChange}
                id="desc"
              />
            </div>
            <div className="inputGroup">
              <fieldset>
                <legend>Select Audience</legend>
                {notifier.map((notif, index) => (
                  <label key={index} className="checkbox">
                    <input
                      type="checkbox"
                      value={notif.name}
                      checked={formData.notifer.some(
                        (notifier) => notifier.name === notif.name
                      )}
                      onChange={handleNotifierChange}
                    />
                    {notif.name}
                  </label>
                ))}
              </fieldset>
            </div>
          </div>

          <div className="submitWrapper">
            <button type="submit" className="create-btn">
              Submit
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={handleBackClick}
            >
              Cancel
            </button>
          </div>
        </form>
        <ToastContainer position="top-center" />
      </div>
    </div>
  );
};

export default CreateNotice;
