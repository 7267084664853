import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../utils/config";
import { adminRequest } from "../../../utils/requestMethods";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";

const CreateTestimonial = () => {
  const initialFormData = {
    student_name: "",
    testimonial: "",
    degree: "",
    start_year: "",
    end_year: "",
    current_job: "",
    current_company: "",
    profile_pic: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, profile_pic: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.profile_pic);

    formDataToSend.append(
      "testimonial",

      JSON.stringify({
        student_name: formData.student_name,
        testimonial: formData.testimonial,
        degree: formData.degree,
        start_year: formData.start_year,
        end_year: formData.end_year,
        current_job: formData.current_job,
        current_company: formData.current_company,
      })
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/testimonial/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "student_name",
      label: "Name",
      type: "text",
      value: formData.student_name,
      onChange: handleChange,
    },
    {
      name: "degree",
      label: "Degree",
      type: "text",
      value: formData.degree,
      onChange: handleChange,
    },
    {
      name: "start_year",
      label: "Start Year",
      type: "text",
      value: formData.start_year,
      onChange: handleChange,
    },
    {
      name: "end_year",
      label: "End Year",
      type: "text",
      value: formData.end_year,
      onChange: handleChange,
    },
    {
      name: "current_job",
      label: "Current Job",
      type: "text",
      value: formData.current_job,
      onChange: handleChange,
    },
    {
      name: "current_company",
      label: "Current Company",
      type: "text",
      value: formData.current_company,
      onChange: handleChange,
    },
    {
      name: "testimonial",
      label: "Testimonial",
      type: "textarea",
      value: formData.testimonial,
      onChange: handleChange,
    },

    {
      name: "profile_pic",
      label: "Profile Picture",
      type: "file",
      value: formData.profile_pic,
      onChange: handleImageChange,
    },
  ];

  return (
    <div className="createTestimonialContainer">
      <CustomForm
        header="Create Testimonial"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Testimonial"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateTestimonial;
