import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";

const CreateExamConfig = () => {
  const initialFormData = {
    name: "",
    courseId: "",
    semesterId: "",
    examId: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setCourses(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourse();
  }, []);

  useEffect(() => {
    if (formData.courseId) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.courseId,
            }
          );
          setSemesters(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.log(error);
        }
      };
      fetchSemester();
    }
  }, [formData.courseId]);

  useEffect(() => {
    const fetchExam = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/exam/list`);
        setExams(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchExam();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/examSetup/create`, {
          name: formData.name,
          examId: formData.examId,
          curriculumId: formData.semesterId,
        }),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "courseId",
      label: "Course",
      type: "select",
      value: formData.courseId,
      onChange: handleChange,
      options: [
        { label: "Select Course", value: "" },
        ...courses.map((c) => ({
          label: c.short_name,
          value: c.id,
        })),
      ],
    },
    {
      name: "semesterId",
      label: "Semester",
      type: "select",
      value: formData.semesterId,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semesters.map((s) => ({
          label: s.semester,
          value: s.id,
        })),
      ],
    },
    {
      name: "examId",
      label: "Exam",
      type: "select",
      value: formData.examId,
      onChange: handleChange,
      options: [
        { label: "Select Exam", value: "" },
        ...exams.map((e) => ({
          label: `${e.name} (${e.examYear})`,
          value: e.id,
        })),
      ],
    },
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
  ];

  return (
    <div className="createExamConfigContainer">
      <CustomForm
        header="Create Exam Config"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateExamConfig;
