import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import List from "../../../components/List/List";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const EventList = () => {
  const headers = [
    "Name",
    "Event Category",
    "Event Date",
    "Location",
    "Status",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/event/list`);
      const fetchedRows = response.data.data.map((e) => ({
        displayData: [
          e.name,
          e.eventCategory.name,
          e.eventDate,
          e.location,
          e.eventStatus,
        ],
        slug: e.slug,
        status: e.eventStatus,
      }));
      setRows(fetchedRows);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setComplete = async (slug) => {
    Swal.fire({
      title: "Are you sure you want to complete this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Complete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/event/complete`,
            {
              slug: slug,
            }
          );
          Swal.fire({
            title: "Completed!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const cancelEvent = async (slug) => {
    Swal.fire({
      title: "Are you sure you want to cancel this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/event/cancel`, {
            slug: slug,
          });
          Swal.fire({
            title: "Event Canceled!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const deleteEvent = async (slug) => {
    Swal.fire({
      title: "Are you sure you want to delete this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/event/delete`, {
            slug: slug,
          });
          Swal.fire({
            title: "Deleted!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };

  const setUpcoming = async (slug) => {
    Swal.fire({
      title: "Are you sure you want to active this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#264796",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/event/upcoming`,
            {
              slug: slug,
            }
          );
          Swal.fire({
            title: "Activated!",
            text: `${response.data.message}`,
            icon: "success",
          });

          fetchData();
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: `${error.response.data.message}`,
            icon: "error",
          });
        }
      }
    });
  };
  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `view/${row.slug}`, text: "View" },
    { link: `edit/${row.slug}`, text: "Edit" },
    {
      link: "#",
      text: "Delete",
      onClick: (e) => {
        e.preventDefault();
        deleteEvent(row.slug);
      },
    },
    {
      link: "#",
      text: "Cancel Event",
      onClick: (e) => {
        e.preventDefault();
        cancelEvent(row.slug);
      },
    },
    {
      link: "#",
      text: "Set Complete",
      onClick: (e) => {
        e.preventDefault();
        setComplete(row.slug);
      },
    },
    {
      link: "#",
      text: "SetUpcoming",
      onClick: (e) => {
        e.preventDefault();
        setUpcoming(row.slug);
      },
    },
  ];

  return (
    <div className="eventListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Events List"
            createButtonLabel="Create Event"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EventList;
