import React, { useEffect, useState } from "react";
import "./CreateSemester.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { BASE_URL } from "../../../utils/config";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const CreateSemester = () => {
  const initialFormData = {
    course_id: "",
  };
  const initialSemesterData = {
    semester: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [semester, setSemester] = useState([initialSemesterData]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [courses, setCourses] = useState([]);

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/course/getName`);
        setCourses(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourse();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSemesterChange = (index, field, value) => {
    const newSemester = [...semester];
    newSemester[index][field] = value;
    setSemester(newSemester);
  };

  const addSemester = () => {
    setSemester([...semester, { ...initialSemesterData }]);
  };

  const removeSemester = (index) => {
    const newSemester = [...semester];
    newSemester.splice(index, 1);
    setSemester(newSemester);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/course/semester/create`, {
          course_id: formData.course_id,
          semester: semester.map((sem) => sem.semester),
        }),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
      setSemester([initialSemesterData]);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="createSemesterContainer">
      <div className="top">
        <span className="backIcon" onClick={handleBackClick}>
          <FaArrowLeftLong />
        </span>
        <span className="headerTitle">Create Semester</span>
      </div>
      <div className="bottom">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="courseWrapper">
            <div className="inputGroup">
              <label htmlFor="courses">Course</label>
              <select
                name="course_id"
                value={formData.course_id}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Course
                </option>
                {courses.map((option, index) => (
                  <option value={option.id} key={index}>
                    {option.short_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="semesterFieldContainer">
            {semester.map((sem, index) => (
              <div key={index} className="semesterField">
                <input
                  type="text"
                  placeholder="Semester Name"
                  value={sem.semester}
                  onChange={(e) =>
                    handleSemesterChange(index, "semester", e.target.value)
                  }
                />
                <div className="buttonGroup">
                  {index === semester.length - 1 && (
                    <button
                      type="button"
                      className="addSemesterButton"
                      onClick={addSemester}
                    >
                      +
                    </button>
                  )}
                  {index === semester.length - 1 && semester.length > 1 && (
                    <button
                      type="button"
                      className="removeSemesterButton"
                      onClick={() => removeSemester(index)}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </form>
        <div className="submitWrapper">
          <button type="submit" className="create-btn" onClick={handleSubmit}>
            Submit
          </button>
          <button
            type="submit"
            className="cancel-btn "
            onClick={handleBackClick}
          >
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateSemester;
