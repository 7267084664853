import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../utils/config";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethods";
import { toast, ToastContainer } from "react-toastify";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const CreateStudent = () => {
  const initialFormData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: "",
    address: "",
    enrolledProgram: "",
    enrolledSemester: "",
    batch: "",
    enrollmentDate: "",
    parentFullName: "",
    parentEmail: "",
    parentPhoneNumber: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [semester, setSemester] = useState([]);
  const [program, setProgram] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/course/getNameAndId`
        );
        setProgram(response.data.data);
        updateAuthToken();
      } catch (error) {
        console.log(error);
      }
    };
    if (formData.enrolledProgram) {
      const fetchSemester = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/course/getSemestersById`,
            {
              id: formData.enrolledProgram,
            }
          );
          setSemester(response.data.data);
          updateAuthToken();
        } catch (error) {
          console.log(error);
        }
      };
      fetchSemester();
    }
    fetchProgram();
  }, [formData.enrolledProgram]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/student/create`, {
          fullName: formData.fullName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          dateOfBirth: formData.dateOfBirth,
          gender: formData.gender,
          address: formData.address,
          enrolledProgram: formData.enrolledProgram,
          enrolledSemester: formData.enrolledSemester,
          batch: formData.batch,
          enrollmentDate: formData.enrollmentDate,
          parentFullName: formData.parentFullName,
          parentEmail: formData.parentEmail,
          parentPhoneNumber: formData.parentPhoneNumber,
        }),
        {
          pending: "Processing your request",
        }
      );

      toast.success(response.data.message, {
        autoClose: 500,
        onClose: () => navigate(-1),
      });

      setFormData(initialFormData);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "fullName",
      label: "Student Name",
      type: "text",
      value: formData.fullName,
      onChange: handleChange,
    },

    {
      name: "email",
      label: "Student Email",
      type: "email",
      value: formData.email,
      tail: "Activation links will be sent to this email.",
      onChange: handleChange,
    },
    {
      name: "phoneNumber",
      label: "Student Phone Number",
      type: "text",
      value: formData.phoneNumber,
      onChange: handleChange,
    },
    {
      name: "parentFullName",
      label: "Parent Name",
      type: "text",
      value: formData.parentFullName,
      onChange: handleChange,
    },
    {
      name: "parentEmail",
      label: "Parent Email",
      type: "email",
      value: formData.parentEmail,
      tail: "Activation links will be sent to this email.",
      onChange: handleChange,
    },
    {
      name: "parentPhoneNumber",
      label: "Parent Phone Number",
      type: "text",
      value: formData.parentPhoneNumber,
      onChange: handleChange,
    },
    {
      name: "dateOfBirth",
      label: "Date Of Birth",
      type: "date",
      value: formData.dateOfBirth,
      onChange: handleChange,
    },
    {
      name: "gender",
      label: "Gender",
      type: "select",
      options: [
        { value: "", label: "Select Gender" },
        { value: "MALE", label: "Male" },
        { value: "FEMALE", label: "Female" },
        { value: "OTHER", label: "Other" },
      ],
      value: formData.gender,
      onChange: handleChange,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "batch",
      label: "Batch",
      type: "text",
      value: formData.batch,
      onChange: handleChange,
    },
    {
      name: "enrolledProgram",
      label: "Enrolled Program",
      type: "select",
      value: formData.enrolledProgram,
      onChange: handleChange,
      options: [
        { label: "Select Program", value: "" },
        ...program.map((p) => ({
          label: p.short_name,
          value: p.id,
        })),
      ],
    },
    {
      name: "enrolledSemester",
      label: "Semester",
      type: "select",
      value: formData.enrolledSemester,
      onChange: handleChange,
      options: [
        { label: "Select Semester", value: "" },
        ...semester.map((s) => ({
          label: s.semester,
          value: s.id,
        })),
      ],
    },
    {
      name: "enrollmentDate",
      label: "Enrolled Date",
      type: "date",
      value: formData.enrollmentDate,
      onChange: handleChange,
    },
  ];

  return (
    <div className="createStudentContainer">
      <CustomForm
        header="Create Student"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create Student"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateStudent;
